import React          from "react";
import {Redirect}     from "react-router-dom";
import axios          from 'axios';
import { connect }    from "react-redux";
import { fetchData } from "../../store";
import '../../App.css';

class Page extends React.Component {

    constructor(props) {

      super();
      this.state = {
        userId:0,
        seconds:0,
        email:"",
        mailcode:"",
        error: false,
        msg: '',
        app:false,
        loading: false
      }

      this.sendCode       = this.sendCode.bind(this)
      this.handleChange   = this.handleChange.bind(this)
      this.handleSubmit   = this.handleSubmit.bind(this)
      this.handleKeyPress = this.handleKeyPress.bind(this)

    }

    redirect(app){
      window.location.href = app
    }

    handleChange(event) {
      const name  = event.target.name;
      const value = event.target.value;
      this.setState({
        [name]: value,
        msg: '',
        error: false,
        success: false,
        warn: false,
      });
    }

    sendCode(e){

        const {email} = this.state;

        if(this.state.seconds > 0){
          return;
        }

        if(!email){
          return alert("please enter your email");
        }

        axios.post('/authorize', {address:email}).then((res) => {
            if (res.data.code !== 0) {
                this.setState({
                    warn: true,
                    msg: res.data.message || '',
                });
            } else {
                this.setState({
                    success: true,
                    msg: res.data.message || 'check your Mailbox'
                });
            }

            this.setState({seconds:20})
            const countdown = () => {
              var left = this.state.seconds;
              console.log("countdown",this.state);
              if(left > 0){
                this.setState({seconds: left - 1})
                setTimeout(countdown,1000);
              }
            }

            countdown();

        });

    }

    componentDidMount( ) {

      axios.get('/user/info', {address:email}).then((res) => {
        if(res.data.data.userInfo){
          this.redirect("/dashboard")
        }
      })

    }

    handleKeyPress(e){
      //console.log("key",e);
      //e.preventDefault();
      //return false;
    }

    handleSubmit(e) {
        e.preventDefault();
        console.log("login",this.state);
        const {email, mailcode} = this.state;
        //check
        if(!email || !mailcode){
          this.setState({warn: true, success: false, error: false, msg: '请输入您的邮箱地址和收到的验证码'});
          return;
        }else{
          this.setState({warn: false, success: false, error: false, msg: ''});
        }

        axios.post('/login', {
            method:"email",
            site:"qusu.io",
            email,
            mailcode,
        }).then((res) => {
            if (res.data.code !== 0) {
                this.setState({
                    msg: res.data.message || 'login failed',
                    error: true,
                    warn:true,
                    loading: false,
                });
            } else {
                this.setState({
                    userId: res.data.data.userId,
                    loading: false,
                });

                if(res.data.data.userId == 1){
                  this.redirect("/admin")
                }else{
                  this.redirect("/dashboard")
                }
            }
        }).catch((err) => {
            console.error(err);
            this.setState({
                msg: 'login error',
                error: true,
                warn:true,
                loading: false,
            });
        });

    }

    render( ) {

        return (
          <div className="">

          <div className="min-h-screen bg-gray-800 flex flex-col justify-center py-12 sm:px-6 lg:px-8">

            <div className="sm:mx-auto sm:w-full sm:max-w-md">

              <a href="/">
                <img className="mx-auto h-24 w-auto" src="/logo-white.png" alt="Warp" />
              </a>

                <h2 className="mt-6 text-center text-4xl font-extrabold text-gray-100">
                  WARP
                </h2>
                <p className="mt-2 text-center text-sm text-gray-200">
                  warp speed
                </p>

            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">

              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form onKeyPress={this.handleKeyPress} onSubmit={this.handleSubmit} >
                  <div>
                    <label className="block text-sm font-medium leading-5 text-gray-700">
                      Email
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input name="email" value={this.state.email}  onChange={this.handleChange} id="email" type="email" required className="w-full mt-2 py-2 px-4 bg-white text-gray-700 border border-gray-300 rounded block placeholder-gray-500 focus:border-blue-500 focus:outline-none focus:shadow-outline" />

                    </div>
                  </div>

                  <div className="mt-6">

                    <label className="block text-sm font-medium leading-5 text-gray-700">
                      code {this.state.seconds > 0 ? <span>
                          {this.state.seconds} seconds later
                        </span>:
                        <button onClick={this.sendCode} className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                          send code
                        </button>
                      }
                    </label>

                    <div className="mt-1 rounded-md shadow-sm">
                      <input id="mailcode" name="mailcode" value={this.state.mailcode} onChange={this.handleChange} type="text" className="w-full mt-2 py-2 px-4 bg-white text-gray-700 border border-gray-300 rounded block placeholder-gray-500 focus:border-blue-500 focus:outline-none focus:shadow-outline" />
                    </div>
                  </div>

                  {this.state.warn?
                  <div className="mt-6">
                    <p className="mt-2 text-sm text-red-600">{this.state.msg}</p>
                  </div>:null}

                  <div className="mt-6">
                    <span className="block w-full rounded-md shadow-sm">
                      <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-700 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                      Login
                      </button>
                    </span>
                  </div>
                </form>

              </div>
            </div>

          </div>



        </div>
        );

    }
}

const mapStateToProps = ( state ) => ( { } );

const mapDispatchToProps = { };

export default connect( mapStateToProps, mapDispatchToProps )( Page );
