import Manager    from "./components/chinese/Manager";
import Login      from "./components/chinese/Login";
import Home       from "./components/chinese/Home";
import User       from "./components/chinese/User";
import Auth       from "./components/chinese/Auth";
import Help       from "./components/chinese/Help";
import About      from "./components/chinese/About";
import Message    from "./components/chinese/Message";

import enLanding  from "./components/english/Home";
import enLogin    from "./components/english/Enter";

const english = [{
  path: "/landing",
  component: enLanding,
  exact: true
},{
  path: "/enter",
  component: enLogin,
  exact: true
}]

const chinese = [{
  path: "/",
  component: Home,
  exact: true
},{
  path: "/user/:id",
  component: User,
  exact: true
},{
  path: "/message/:id",
  component: Message,
  exact: true
},{
  path: "/help",
  component: Help,
  exact: true
},{
  path: "/about",
  component: About,
  exact: true
},{
  path: "/index",
  component: Manager,
  exact: true
},{
  path: "/auth/:code",
  component: Auth,
  exact: true
},{
  path: "/home",
  component: Login,
  exact: true
}];

const routes = [].concat(chinese).concat(english)

export default routes
