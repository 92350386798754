import React          from "react";
import {Fragment}     from "react";
import axios          from 'axios';
import { connect }    from "react-redux";
import { fetchData }  from "../../store";

class Page extends React.Component {

    constructor(props) {

      super();
      this.state = {
        userId:0,
        seconds:0,
        email:"",
        mailcode:"",
        error: false,
        msg: '',
        loading: false
      }

      this.handleChange   = this.handleChange.bind(this)
      this.handleSubmit   = this.handleSubmit.bind(this)
      this.handleKeyPress = this.handleKeyPress.bind(this)

    }

    handleChange(event) {
      const name  = event.target.name;
      const value = event.target.value;
      this.setState({
        [name]: value,
        msg: '',
        error: false,
        success: false,
        warn: false,
      });
    }

    componentDidMount( ) {
    }

    handleKeyPress(e){
      //console.log("key",e);
      //e.preventDefault();
      //return false;
    }

    handleSubmit(e) {
        e.preventDefault();
        const {email, mailcode} = this.state;
        //check
        if(!email || !mailcode){
          this.setState({warn: true, success: false, error: false, msg: 'please enter your email and passcode'});
          return;
        }else{
          this.setState({warn: false, success: false, error: false, msg: ''});
        }
        axios.post('/login', {
            method:"email",
            site:"qusu.io",
            email,
            mailcode,
        }).then((res) => {
            if (res.data.code !== 0) {
                this.setState({
                    msg: res.data.message || 'login failed',
                    error: true,
                    warn:true,
                    loading: false,
                });
            } else {
              if(res.data.data.userId == 1){
                window.location.href = "/admin"
              }
            }
        }).catch((err) => {
            console.error(err);
            this.setState({
                msg: 'login error',
                error: true,
                warn:true,
                loading: false,
            });
        });

    }

    render( ) {

        return (
          <div>
          <div className="min-h-screen bg-gray-200 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">

              <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                WARP
              </h2>

            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">

              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form onKeyPress={this.handleKeyPress} onSubmit={this.handleSubmit} >
                  <div>
                    <label className="block text-sm font-medium leading-5 text-gray-700">
                      Username
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input name="email" value={this.state.email}  onChange={this.handleChange} id="email" type="email" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                    </div>
                  </div>

                  <div className="mt-6">

                    <label className="block text-sm font-medium leading-5 text-gray-700">
                      Password
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input id="mailcode" name="mailcode" value={this.state.mailcode} onChange={this.handleChange} type="text" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                    </div>
                  </div>

                  <div className="mt-6">
                    <span className="block w-full rounded-md shadow-sm">
                      <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                        Login
                      </button>
                    </span>
                  </div>
                </form>

                <div className="mt-6">

                </div>
              </div>
            </div>
          </div>
        </div>
        );

    }
}

const mapStateToProps = ( state ) => ( { } );

const mapDispatchToProps = { };

export default connect( mapStateToProps, mapDispatchToProps )( Page );
