import React          from "react";
import {Fragment}     from "react";
import axios          from 'axios';
import { connect }    from "react-redux";

class Page extends React.Component {

    constructor(props) {
      super();
    }

    render( ) {

        return (
          <div class="bg-gray-100 max-w-7xl mx-auto px-4 py-5 sm:px-6 lg:px-8">
            <div class="max-w-3xl mx-auto mb-100">
              <div class="bg-white shadow sm:rounded-lg">
                <div class="px-4 py-5 sm:p-6">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    您的曲速加速账户已经暂停
                  </h3>
                  <div class="mt-2 max-w-xl text-sm text-gray-500">
                    <p>
                      请登陆曲速官网续费
                    </p>
                  </div>
                  <div class="mt-5">
                    https://www.qusumao.com
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

    }
}

const mapStateToProps = ( state ) => ( { } );

const mapDispatchToProps = { };

export default connect( mapStateToProps, mapDispatchToProps )( Page );
