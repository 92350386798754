import React          from "react";
/* This example requires Tailwind CSS v2.0+ */
import { Fragment,useEffect } from 'react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function Example({match}) {

  return (
    <div className="min-h-screen bg-gray-900">
      <header>
        <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="#">
              <span className="sr-only">曲速猫</span>
              <img
                className="h-8 w-auto sm:h-10"
                src="/logo-brand-dark.png"
                alt=""
              />
            </a>
          </div>
          </div>
      </header>
      <main>
          <div class="bg-gray-800">
           <div class="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
             <h2 class="text-3xl font-extrabold text-white sm:text-4xl">
               <span class="block">无法联机 - 联机会员过期</span>
               <span class="block"></span>
             </h2>
             <p class="mt-4 text-lg leading-6 text-indigo-200">请购买联机会员后，重新连接</p>
             <a href="https://qusumao.com/console/shop/membership" class="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 sm:w-auto">
              前往商店
             </a>
           </div>
          </div>
      </main>
    </div>
  )
}


